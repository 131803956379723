import React, { useState } from "react"
import Layout from "../components/layout"
import { Grid, Box, Embed, Heading } from "theme-ui"

const style = {
  heading: {
    textAlign: "center",
    fontSize: "25px",
    fontFamily: "Josefin Sans",
    fontWeight: "100",
    marginBottom: "20px",
    maxWidth: "60%",
    margin: "auto",
  },
  title: { textDecoration: "underline" },
}

function KaoticaPage() {
  const [burgerIsOpen, setBurgerIsOpen] = useState(false)
  const videos = [
    {
      url: "https://player.vimeo.com/video/604412480",
      alt: "Sam Falconi - Reel",
    },
  ]

  return (
    <Layout burgerIsOpen={burgerIsOpen} setBurgerIsOpen={setBurgerIsOpen}>
      {/* <img src="./Kaotica_Logo_Central_Comp_RGB_Red.svg" /> */}
      <Heading style={style.heading}></Heading>

      <Grid gap={0} sx={{ width: "85%", mx: "auto" }} columns={[1]}>
        {videos.map(video => {
          return (
            <Box key={video.alt} bg="primary">
              <Embed
                src={video.url}
                alt={video.alt}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              />
            </Box>
          )
        })}
      </Grid>
    </Layout>
  )
}

export default KaoticaPage
